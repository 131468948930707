document.addEventListener('DOMContentLoaded', () => {
  /**
   * Deep-link logic is being handled by deep-link.js.
   * The following logic is legacy and should be removed when all pages using old tabs are converted to new tabs component.
   */
  const isMobile = window.screen.width <= 768;
  const mobileTabs = document.querySelectorAll('.mob-tab-link');

  if (mobileTabs) {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!window.location.hash) {
      activateHashRequest(mobileTabs);
    }
    window.addEventListener('popstate', () => {
      activateHashRequest(mobileTabs);
    });
  }

  function activateHashRequest(mobileTabs) {
    if (isMobile) {
      const hash = window.location.hash;
      activateMobileTab(mobileTabs, hash);
    }
  }

  function activateMobileTab(mobileTabs, hash) {
    const mobHash = hash.replace('#', 'mob-tab-');
    mobileTabs.forEach((element) => {
      if (element.id === mobHash) {
        setTimeout(() => {
          if (element.classList.contains('collapsed')) {
            element.click();
          }
          element.scrollIntoView();
        }, 100);
        return true;
      }
    });
    return false;
  }
});
