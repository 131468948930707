function initInputGroupEvents() {
  document.querySelectorAll('.hePdpStuInsToggleBtnGroup').forEach((btnGroup) => {
    const inputGroup = btnGroup.querySelectorAll('input[type="radio"]');
    const moveFocus = (currentIndex, direction) => {
      const newIndex = (currentIndex + direction + inputGroup.length) % inputGroup.length;
      inputGroup[newIndex].focus();
    };

    inputGroup.forEach((radioEl, index) => {
      radioEl.addEventListener('keydown', (event) => {
        if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
          event.preventDefault();
          // Move focus to the next radio
          moveFocus(index, 1);
        }

        if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
          event.preventDefault();
          // Move focus to the previous radio
          moveFocus(index, -1);
        }

        // Handle Space or Enter to select the radio button
        if (event.key === ' ' || event.key === 'Enter') {
          radioEl.checked = true; // Select the focused radio button
          // Create and dispatch click event
          const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          });
          radioEl.dispatchEvent(clickEvent);
          // Create and dispatch change event
          const changeEvent = new Event('change', {
            bubbles: true,
            cancelable: true,
          });
          radioEl.dispatchEvent(changeEvent);
        }

        // Handle Tab to move focus
        if (event.key === 'Tab') {
          // Move focus to the next or previous focusable element
          if (event.shiftKey) {
            // Shift + Tab: Move focus to the previous element
            const prevIndex = (index - 1 + inputGroup.length) % inputGroup.length;
            if (prevIndex === inputGroup.length - 1) {
              // No previous radio button, let default behavior handle it
              return;
            }
            event.preventDefault();
            inputGroup[prevIndex].focus();
          } else {
            // Tab: Move focus to the next element
            const nextIndex = (index + 1) % inputGroup.length;
            if (nextIndex === 0) {
              // No next radio button, let default behavior handle it
              return;
            }
            event.preventDefault();
            inputGroup[nextIndex].focus();
          }
        }
      });

      radioEl.addEventListener('change', function (event) {
        const selectedValue = event.target.value;

        // Dispatch a custom event
        const customEvent = new CustomEvent('studentInstructorToggleGroupChange', {
          detail: { value: selectedValue },
          bubbles: true,
        });
        btnGroup.dispatchEvent(customEvent);
      });
    });
  });
}

if (document.readyState !== 'loading') {
  initInputGroupEvents();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    initInputGroupEvents();
  });
}
