(function () {
  /**
   * Activates a component section based on the URL hash.
   * Support components having different elements in different view breakpoints.
   * For nested elements, all parents are also activated.
   * Supported components: Tabs and Accordions.
   */
  document.addEventListener('DOMContentLoaded', () => {
    initDeepLink();
  });

  function initDeepLink() {
    activateElementByURLHash();
    window.addEventListener('popstate', () => {
      activateElementByURLHash();
    });
  }

  function activateElementByURLHash() {
    const targetElementId = window.location.hash.slice(1);
    if (targetElementId) {
      activateElement(getDeepLinkElementsMap(), targetElementId);
    }
  }

  /**
   * Returns a map of page elements listening to URL hash.
   *   key: URL hash element is expecting
   *   value: List of elements to be activated.
   */
  function getDeepLinkElementsMap() {
    const deepLinkElementsMap = {};
    addTabs(deepLinkElementsMap);
    addAccordions(deepLinkElementsMap);
    addCollapse(deepLinkElementsMap);
    return deepLinkElementsMap;
  }

  function addTabs(deepLinkElementsMap) {
    document.querySelectorAll('.nav-link').forEach((element) => {
      if (element.id) {
        addElement(deepLinkElementsMap, element.id, element);
      }
    });
  }

  function addAccordions(deepLinkElementsMap) {
    document.querySelectorAll('.accordion-header').forEach((element) => {
      const accordionButton = element.querySelector('.accordion-button');
      if (accordionButton) {
        addElement(deepLinkElementsMap, getAccordionId(element.id), accordionButton);
      }
    });
  }

  function addCollapse(deepLinkElementsMap) {
    document.querySelectorAll('.collapse-deep-link button[data-bs-toggle="collapse"]').forEach((element) => {
      if (element.id) {
        addElement(deepLinkElementsMap, element.id, element);
      }
    });
  }

  /**
   * Tab components are automatically converted to accordions in mobile breakpoints.
   * To avoid duplicated ids, "accordion-" prefix is added to accordion ids.
   */
  function getAccordionId(id) {
    if (id.startsWith('accordion-')) {
      return id.slice(10);
    }
    return id;
  }

  function addElement(deepLinkElementsMap, id, element) {
    if (deepLinkElementsMap[id]) {
      deepLinkElementsMap[id].push(element);
    } else {
      deepLinkElementsMap[id] = [element];
    }
  }

  function activateElement(deepLinkElementsMap, targetElementId) {
    const targetElements = deepLinkElementsMap[targetElementId];
    if (targetElements) {
      targetElements.forEach((targetElement) => {
        activateParents(deepLinkElementsMap, targetElement);
        if (!isOpen(targetElement)) {
          targetElement.click();
        }
      });
    }
  }

  function isOpen(element) {
    return element.getAttribute('aria-expanded') == 'true' || element.getAttribute('aria-selected') == 'true';
  }

  /**
   * Identifies whether a component is nested by looking for a parent content section and activates that.
   */
  function activateParents(deepLinkElementsMap, targetElement) {
    const parentContentSection = targetElement.closest('.accordion-body');
    if (parentContentSection) {
      const parentContentElement = parentContentSection.closest(
        '.tab-content>.tab-pane, .accordion-item>.accordion-collapse'
      );
      if (parentContentElement) {
        activateParentElement(deepLinkElementsMap, parentContentElement);
      }
    }
  }

  function activateParentElement(deepLinkElementsMap, parentContentSection) {
    const parentContentSectionId = `#${parentContentSection.id}`;
    Object.entries(deepLinkElementsMap).forEach(([id, elements]) => {
      elements.forEach((element) => {
        if (element.getAttribute('data-bs-target') === parentContentSectionId) {
          activateElement(deepLinkElementsMap, `${id}`);
        }
      });
    });
  }
})();
