document.addEventListener('DOMContentLoaded', () => {
  const collapses = document.querySelectorAll('.outside-click-hide');
  if (collapses) {
    document.body.addEventListener('click', (event) => {
      const target = event.target;
      const targetCollapse = target.closest('.outside-click-hide');
      const clickOutside = !targetCollapse;
      if (clickOutside) {
        collapses.forEach((collapse) => {
          if (collapse.classList.contains('show')) {
            if (!collapse.parentElement.classList.contains('tab-pane')) {
              // eslint-disable-next-line no-undef
              const instance = bootstrap.Collapse.getOrCreateInstance(collapse);
              instance.hide();
            }
          }
        });
      } else {
        collapses.forEach((collapse) => {
          if (!collapse.isSameNode(targetCollapse)) {
            if (collapse.classList.contains('show')) {
              if (!collapse.parentElement.classList.contains('tab-pane')) {
                // eslint-disable-next-line no-undef
                const instance = bootstrap.Collapse.getOrCreateInstance(collapse);
                instance.hide();
              }
            }
          }
        });
      }
    });
  }

  const collapseButtons = document.querySelectorAll('.collapse-deep-link button[data-bs-toggle="collapse"]');
  const url = new URL(window.location.href);
  collapseButtons.forEach((collapseButton) => {
    collapseButton.addEventListener('click', () => {
      url.hash = '#' + collapseButton.id;
      history.pushState({}, null, url.href);
    });
  });
});
