window.addEventListener('DOMContentLoaded', () => {
  _handleCardButtons();
});

function getScrollStep(scrollContent) {
  if (window.innerWidth < 576) {
    return scrollContent.scrollWidth / scrollContent.childElementCount;
  } else if (window.innerWidth < 768) {
    return (scrollContent.scrollWidth / scrollContent.childElementCount) * 2;
  } else if (window.innerWidth < 992) {
    return (scrollContent.scrollWidth / scrollContent.childElementCount) * 3;
  } else {
    return (scrollContent.scrollWidth / scrollContent.childElementCount) * 4;
  }
}

function _handleCardButtons() {
  const scrollContainer = document.querySelectorAll('.scroll-container');
  console.log(scrollContainer);
  scrollContainer.forEach((container) => {
    const leftScroll = container.querySelector('.left-scroll');
    const rightScroll = container.querySelector('.right-scroll');
    const scrollContent = container.querySelector('.scroll-content');

    if (leftScroll && rightScroll) {
      setTimeout(() => {
        if (scrollContent.scrollWidth <= scrollContent.clientWidth) {
          scrollContent.classList.add('w-100');
          leftScroll.classList.add('d-none');
          rightScroll.classList.add('d-none');
        } else {
          scrollContent.classList.remove('w-100');
          leftScroll.classList.remove('d-none');
          rightScroll.classList.remove('d-none');
        }
      }, 100);

      leftScroll.addEventListener('click', () => {
        scrollContent.scroll({
          left: scrollContent.scrollLeft - getScrollStep(scrollContent),
          behavior: 'smooth',
        });
      });

      rightScroll.addEventListener('click', () => {
        scrollContent.scroll({
          left: scrollContent.scrollLeft + getScrollStep(scrollContent),
          behavior: 'smooth',
        });
      });
    }
  });
}
