document.addEventListener('DOMContentLoaded', () => {
  loadCarouselComponentEventListeners();
});

function loadCarouselComponentEventListeners() {
  document.querySelectorAll('.carousel').forEach((carouselContainer) => {
    let carouselId = carouselContainer.id;
    let playPauseBtn = carouselContainer.querySelector('.mhe-play-pause-carousel-btn');
    let prevBtn = carouselContainer.querySelector('.carousel-control-prev');
    let nextBtn = carouselContainer.querySelector('.carousel-control-next');
    let dots = carouselContainer.querySelectorAll('.carousel-dots');
    // eslint-disable-next-line no-undef
    let carousel = new bootstrap.Carousel(carouselContainer);
    //Changed the _isPaused carousel property for this paused flag to prevent bugs with the hover/focus pause estatus
    let carouselPaused = false;
    let focusList = [];

    if (playPauseBtn) {
      focusList.push(playPauseBtn);
    }
    if (prevBtn) {
      focusList.push(prevBtn);
    }
    if (nextBtn) {
      focusList.push(nextBtn);
    }
    dots.forEach((dot) => {
      focusList.push(dot);
    });

    focusList.forEach((element) => {
      element.addEventListener('focus', () => {
        carousel.pause();
      });
      element.addEventListener('blur', (e) => {
        let focusOutsideCarousel = !e.relatedTarget?.closest(`#${carouselId}`);
        if (focusOutsideCarousel && !carouselPaused) {
          carousel.cycle();
        }
      });
    });

    let clickListener = function () {
      if (carousel && playPauseBtn) {
        if (carouselPaused) {
          carousel.cycle();
          carouselPaused = false;
          playPauseBtn.setAttribute('aria-label', 'Pause');
          playPauseBtn.querySelector('uxt-icon').setAttribute('icon', 'pause');
        } else {
          carousel.pause();
          carouselPaused = true;
          playPauseBtn.setAttribute('aria-label', 'Play');
          playPauseBtn.querySelector('uxt-icon').setAttribute('icon', 'play');
        }
      }
    };
    playPauseBtn?.addEventListener('click', clickListener);
  });
}
