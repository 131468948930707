document.addEventListener('DOMContentLoaded', function () {
  const collapseElementList = [].slice.call(document.querySelectorAll('.card-buttons-component-collapse'));

  collapseElementList.forEach(function (collapseEl) {
    collapseEl.addEventListener('shown.bs.collapse', function () {
      const collapseButton = collapseEl.closest('.mh-card-buttons-component').querySelector('.card-buttons-collapse');
      collapseButton.innerHTML = collapseButton.dataset.collapseLabel;
    });

    collapseEl.addEventListener('hidden.bs.collapse', function () {
      const collapseButton = collapseEl.closest('.mh-card-buttons-component').querySelector('.card-buttons-collapse');
      collapseButton.innerHTML = collapseButton.dataset.expandLabel;
    });
  });
});
