document.addEventListener('DOMContentLoaded', () => {
  const textLinkClasses = [
    'link-primary',
    'link-primary-underline',
    'link-dark',
    'link-dark-underline',
    'link-accent',
    'link-accent-underline',
    'link-light',
    'link-light-underline',
  ];

  for (let i = 0; i <= textLinkClasses.length; i++) {
    var elements = document.getElementsByClassName(textLinkClasses[i]);
    for (let item of elements) {
      if (item.parentNode.nodeName == 'A' && item.nodeName == 'SPAN') {
        item.classList.remove(textLinkClasses[i]);
        item.parentNode.classList.add(textLinkClasses[i]);
      }
    }
  }
});
