/*
 * This script is intended to adjust the scrolling behavior when navigating to content anchored by the URL hash.
 * It applies a `scroll-margin-top` style to the target element, identified dynamically from the URL anchor.
 * The value for `scroll-margin-top` is taken from a CSS variable containing the header elements total height.
 * The script assumes that any scrolling occurring within the first second after page load is due to
 * auto-scrolling to a URL anchor. After this, it assumes any scrolling is user-initiated.
 */

let isScrolling = false;
let scrollTimeout = null;

window.addEventListener(
  'scroll',
  function () {
    // Clear the previous timeout
    window.clearTimeout(scrollTimeout);

    // Set isScrolling to true
    isScrolling = true;

    // Set a timeout to run after scrolling ends
    scrollTimeout = setTimeout(function () {
      // After 100 ms with no scrolling, set isScrolling to false
      isScrolling = false;
    }, 100);
  },
  { passive: true }
);

window.addEventListener('load', () => {
  setAnchorScroll();
});

// Function to check if the window is scrolling
function isWindowScrolling() {
  return isScrolling;
}

function getOffsetTop(element) {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

function setAnchorScroll() {
  const header = document.querySelector('header.sticky-top');

  if (!header) return;

  const navHeight = getComputedStyle(document.documentElement).getPropertyValue(
    '--theme-corporate-navbar-container-height'
  );

  const setupAutoScroll = (isAutoScrolling) => {
    const id = window.location.hash.slice(1);

    if (isAutoScrolling && id) {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          let targetElementY = getOffsetTop(element);
          let winScrollY = window.scrollY;
          if (!isWindowScrolling() && Math.abs(winScrollY - targetElementY) >= 100) {
            element.style.scrollMarginTop = navHeight;
            window.scrollTo({ top: window.scrollY + element.getBoundingClientRect().y, behavior: 'smooth' });
          }
        }
      }, 500);
    }
  };
  // Set up initial auto scrolling
  setupAutoScroll(true);
}
