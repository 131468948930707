document.addEventListener('DOMContentLoaded', () => {
  loadVideoComponentEventListeners();
});

function loadVideoComponentEventListeners() {
  const reducedMotionPreference = window.matchMedia('(prefers-reduced-motion: reduce)');
  document.querySelectorAll('.video-container').forEach((videoContainer) => {
    if (videoContainer.hasAttribute('data-has-overlay')) {
      let playPauseBtn = videoContainer.querySelector('.mhe-play-pause-video-btn');
      let video = videoContainer.querySelector('.mhe-video-comp');
      let videoOverlay = videoContainer.querySelector('.video-overlay-content-container');
      let mouseOverListener = function () {
        playPauseBtn.style.display = 'block';
      };
      videoOverlay.addEventListener('mouseover', mouseOverListener);
      let mouseleaveListener = function () {
        playPauseBtn.style.display = 'none';
      };
      videoOverlay.addEventListener('mouseleave', mouseleaveListener);
      let clickListener = function () {
        if (video && playPauseBtn) {
          if (video.paused) {
            video.play();
            playPauseBtn.querySelector('uxt-icon').setAttribute('icon', 'pause');
          } else {
            video.pause();
            playPauseBtn.querySelector('uxt-icon').setAttribute('icon', 'play');
          }
        }
      };
      videoOverlay.addEventListener('click', clickListener);
      if (reducedMotionPreference.matches) {
        playPauseBtn.querySelector('uxt-icon').setAttribute('icon', 'play');
      }
    }
  });
  if (reducedMotionPreference.matches) {
    let videos = document.querySelectorAll('.mhe-video-comp');
    videos.forEach((video) => {
      if (video.getAttribute('autoplay')) {
        video.setAttribute('autoplay', false);
        video.pause();
      }
    });
  }
}
