document.addEventListener('DOMContentLoaded', () => {
  allowNestedModal();
  allowNestedNavModal();

  function allowNestedModal() {
    let modalList = document.querySelectorAll('.modal.redi-modal-handle');
    modalList.forEach((m) => {
      m.addEventListener('shown.bs.modal', function () {
        let parent = m.parentElement.closest('.modal.redi-modal-handle');
        if (parent != null) {
          parent.style.display = 'block';
          parent.classList.add('show');
          const parentHeight = parent.querySelector('.modal-content').offsetHeight;
          m.querySelector('.modal-content').style.minHeight = parentHeight + 'px';
        }
      });
      m.addEventListener('hidden.bs.modal', function () {
        let parent = m.parentElement.closest('.modal.redi-modal-handle');
        if (parent != null) {
          // eslint-disable-next-line no-undef
          const savedParent = bootstrap.Modal.getInstance(parent);
          savedParent.show();
        }
      });
    });
  }

  function allowNestedNavModal() {
    const modalList = document.querySelectorAll('.modal.nav-modal');
    modalList.forEach((m) => {
      m.addEventListener('shown.bs.modal', function () {
        let parent = m.parentElement.closest('.modal.nav-modal');
        if (parent != null) {
          parent.style.display = 'block';
          parent.classList.add('show');
          const parentHeight = parent.querySelector('.modal-content').offsetHeight;
          m.querySelector('.modal-content').style.minHeight = parentHeight + 'px';
        }
      });
      m.querySelectorAll('button.close').forEach((btnEl) =>
        btnEl.addEventListener('click', () =>
          btnEl.closest('.modal.nav-modal').setAttribute('data-custom-close', 'true')
        )
      );
      m.addEventListener('hidden.bs.modal', () => {
        if (m.getAttribute('data-custom-close')) {
          m.removeAttribute('data-custom-close');
          let parent = m;
          while ((parent = parent.parentElement.closest('.modal.nav-modal'))) {
            parent.style.display = 'none';
            parent.classList.remove('show');
          }
          return;
        }
        let parent = m.parentElement.closest('.modal.nav-modal');
        if (parent != null) {
          // eslint-disable-next-line no-undef
          bootstrap.Modal.getInstance(parent).show();
        }
      });
    });
  }
});
